
import {defineComponent, onMounted, reactive, toRefs} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import {IFeedbackDetail} from "@/models/logistics_model";
import {useRoute, useRouter} from "vue-router";
import {dateFormat} from "@/utils/common";
import {message} from "ant-design-vue/es";

interface IState {
  detail?: IFeedbackDetail
  loading: boolean,
  reply: string
}

export default defineComponent({
  name: "FeedbackDetail",
  setup() {
    let editId: any;
    const route = useRoute()
    const router = useRouter()

    const state: IState = reactive({
      detail: undefined,
      loading: false,
      reply: ''
    })

    // 获取文章详情
    const getDetail = async () => {
      const {id} = route.query
      if (id) {
        editId = id
        const res = await ApiLogistics.getFeedbackDetail(id.toString())
        if (res && res.error_code === 0 && res.data) {
          const data = res.data
          state.detail = data
        }
      }
    }

    // 回复
    const onReply = async () => {
      if (!state.reply) {
        message.destroy()
        message.warning('请输入回复内容')
        return
      }
      state.loading = true
      const res = await ApiLogistics.replyFeedback({pid: state.detail?.id, reply: state.reply})
      message.destroy()
      state.loading = false
      if (res && res.error_code === 0) {
        message.success('回复成功')
        state.reply = ''
        await getDetail()
      } else {
        message.error(res?.msg || '回复失败');
      }
    }

    onMounted(() => {
      getDetail()
    })

    return {
      ...toRefs(state),
      dateFormat,
      onReply,
    }
  }
})
